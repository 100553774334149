import React, { Component } from 'react';
import { Typography, Paper, TextField, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import LockOpen from '@material-ui/icons/LockOpen';
import { getAuthToken } from '../apis/auth';

const containerStyle = {
  margin: '0 auto',
  maxWidth: '600px',
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      notificationType: '',
      notification: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(e) {
    e.preventDefault();

    try {
      const response = await getAuthToken(this.state.username, this.state.password);
      if (response.data.status !== 'success') {
        this.setState({ notificationType: 'error', notification: response.data.msg })
      } else {
        this.setState({ notificationType: 'success', notification: 'Logged in successfully' })
        document.cookie = `authToken=${response.data.id_token}`;
        
        if (this.props.location.state) {
          this.props.history.replace(this.props.location.state.from);
        } else {
          this.props.history.replace('/');
        }
      }
    } catch (ex) {
      this.setState({ notificationType: 'error', notification: 'Error logging in' })
    }
  }

  render() {
    return (
      <div style={containerStyle}>
        <Typography variant="h2" component="h1" style={{ marginBottom: '30px', textAlign: 'center' }}>Administrator Login</Typography>
        <Paper elevation={3}>
          <div style={{ padding: '20px' }}>
            <form onSubmit={this.onSubmit}>
              <TextField fullWidth id="input-username" name="input-username" label="Username" variant="outlined" style={{ marginBottom: '20px' }} onChange={(e) => this.setState({ username: e.target.value })}  value={this.state.username} />
              <TextField fullWidth id="input-password" name="input-password" label="Password" variant="outlined" type="password" style={{ marginBottom: '20px' }} onChange={(e) => this.setState({ password: e.target.value })}  value={this.state.password} />
              <Typography variant="body1">If you do not have an account and wish to request one then please contact <a href="mailto:someone@yoursite.com?subject=Covid Status Account Request">someone@yoursite.com</a></Typography>
              <br />
              <Button type="submit" color="primary" variant="contained" size="large" startIcon={<LockOpen />} disabled={!this.state.username || !this.state.password}>Login</Button>
            </form>
          </div>
        </Paper>
        <Snackbar
          open={this.state.notification}
          autoHideDuration={6000}
          onClose={() => this.setState({ notification: '', notificationType: '' })}
        >
          <MuiAlert elevation={6} variant="filled" severity={this.state.notificationType}>{this.state.notification}</MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

export default Login;
