import React, { Component } from 'react';
import { Button, Typography, TextField, Paper, Modal, Backdrop, Fade } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import queryString from 'query-string';
import Map from './Map';
import PostcodeInformation from './PostcodeInformation';
// import AlertSignup from './AlertSignup';
import { getPostcodeInfoFromPostcode, getPostcodeInfoFromLocation, getOutcodesByStatus, getOutcodeInfoByOutcode } from '../apis/postcodes';

const containerStyle = {
  margin: '0 auto',
  maxWidth: '1170px',
};

class PostcodeSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postcode: '',
      outcode: '',
      postcodeError: '',
      postcodeSearching: false,
      postcodeInfo: null,
      mapCentre: { lat: 56.9, lng: -3.5 }, // Scotland
      email: '',
      mobile: '',
      alertsModalOpen: false,
      redOutcodes: [],
      amberOutcodes: [],
    };

    this.onPostcodeSearch = this.onPostcodeSearch.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onAlertSignup = this.onAlertSignup.bind(this);
    this.onSubscribe = this.onSubscribe.bind(this);
    this.areaSelected = this.areaSelected.bind(this);
  }

  async getCovidAreas() {
    const redResponse = await getOutcodesByStatus('red');
    const redOutcodes = redResponse.data.outcodes.map(outcode => outcode.outcode);
    const amberResponse = await getOutcodesByStatus('amber');
    const amberOutcodes = amberResponse.data.outcodes.map(outcode => outcode.outcode);

    this.setState({ redOutcodes, amberOutcodes })
  }

  async componentDidMount() {
    this.getCovidAreas();

    if (this.props.location.search) {
      const parsed = queryString.parse(this.props.location.search);
      this.setState({ postcode: parsed.postcode });
      await this.onPostcodeSearch(null, parsed.postcode);
    }
    
  }

  async onPostcodeSearch(e, postcode) {
    if (e) {
      e.preventDefault();
    }

    try {
      this.setState({ outcode: '', postcodeError: '', postcodeSearching: true });
      const postcodeInfo = await getPostcodeInfoFromPostcode(this.state.postcode || postcode);
      const result = postcodeInfo.data;
      const mapCentre = {
        lat: parseFloat(result.latitude),
        lng: parseFloat(result.longitude),
      };
      this.setState({ postcode: result.postcode, postcodeSearching: false, postcodeInfo: result, mapCentre, outcode: result.outcode });
      this.props.history.push(`?postcode=${result.postcode}`);
    } catch (ex) {
      let error = ex.message;
      if (ex.response) {
        error = ex.response.data.error;
      }
      this.setState({ postcodeInfo: null, postcodeSearching: false, postcodeError: error });
    }
  }

  async onMapClick(clickInfo) {
    const { lat, lng } = clickInfo;
    try {
      this.setState({ postcodeError: '', postcodeSearching: true });
      const postcodeInfo = await getPostcodeInfoFromLocation(lng, lat);
      if (!postcodeInfo.data.result) {
        throw Error('Invalid Location');
      }
      const result = postcodeInfo.data.result[0];
      const mapCentre = {
        lat: result.latitude,
        lng: result.longitude,
      };
      this.setState({ postcode: result.postcode, postcodeSearching: false, mapCentre });
      this.props.history.push(`?postcode=${result.postcode}`);
      await this.onPostcodeSearch();
    } catch (ex) {
      let error = ex.message;
      if (ex.response) {
        error = ex.response.data.error;
      }
      this.setState({ postcodeSearching: false, postcodeError: error });
    }
  }

  onAlertSignup() {
    this.setState({ alertsModalOpen: true });
  }

  onSubscribe(e) {
    e.preventDefault();
    this.setState({ alertsModalOpen: false });
  }

  async areaSelected(outcode) {
    this.setState({ postcode: '', postcodeError: '', outcode });
    const response = await getOutcodeInfoByOutcode(outcode);
    this.setState({ postcodeInfo: response.data });
  }

  renderAlertsModal() {
    return (
      <div style={{ maxWidth: '400px', margin: '0 auto' }}>
        <Paper elevation={3}>
          <div style={{ padding: '20px' }}>
            <Typography variant="h4" style={{ paddingBottom: '10px' }}>Subscribe to Alerts</Typography>
            <Typography variant="body1" style={{ paddingBottom: '15px' }}>Enter your email address and/or mobile phone number to receive alerts whenever the Covid-19 status for postcode {this.state.postcode} changes.</Typography>
            <form onSubmit={this.onSubscribe}>
              <TextField style={{ paddingBottom: '15px' }} fullWidth id="input-email" name="input-email" label="Email Address" variant="outlined" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />
              <TextField style={{ paddingBottom: '15px' }} fullWidth id="input-mobile" name="input-mobile" label="Mobile Number" variant="outlined" onChange={(e) => this.setState({ mobile: e.target.value })} value={this.state.mobile} />
              <Button type="submit" color="primary" variant="contained" size="large" startIcon={<AnnouncementIcon />}  style={{ marginLeft: '10px', marginTop: '6px' }} disabled={!this.state.email && !this.state.mobile}>Subscribe</Button>
            </form>
          </div>
        </Paper>
      </div>
    );
  }

  render() {
    const showMarker = !!this.state.postcodeInfo && !!this.state.postcodeInfo.postcode;
    const zoomLevel = !!this.state.postcodeInfo;
    return (
      <div style={containerStyle}>
        <Typography variant="h2" component="h1" style={{ marginBottom: '30px', textAlign: 'center' }}>Scotland Coronavirus Status</Typography>
        <Paper elevation={3} style={{ marginBottom: '10px' }}>
          <div style={{ padding: '20px' }}>
            <Typography variant="body1" style={{ marginBottom: '10px' }}>Enter your postcode or touch a place on the map to show the current status Coronavirus in your area</Typography>
            <form onSubmit={this.onPostcodeSearch}>
              <TextField id="input-postcode" name="input-postcode" label="Postcode" variant="outlined" onChange={(e) => this.setState({ postcode: e.target.value })} error={!!this.state.postcodeError} helperText={this.state.postcodeError} disabled={this.state.postcodeSearching} value={this.state.postcode} />
              <Button type="submit" color="primary" variant="contained" size="large" startIcon={<SearchIcon />} disabled={this.state.postcodeSearching || !this.state.postcode} style={{ marginLeft: '10px', marginTop: '6px' }}>Search</Button>
            </form>
          </div>
        </Paper>
        <PostcodeInformation postcodeInfo={this.state.postcodeInfo} />
        {/*<AlertSignup postcodeInfo={this.state.postcodeInfo && this.state.postcodeInfo.status} onAlertSignup={this.onAlertSignup} />*/}
        <Map centre={this.state.mapCentre} zoom={zoomLevel ? 16 : 7} showMarker={showMarker} onClick={this.onMapClick} redOutcodes={this.state.redOutcodes} amberOutcodes={this.state.amberOutcodes} areaSelected={this.areaSelected} outcode={this.state.outcode} />
        <Modal
          open={this.state.alertsModalOpen}
          onClose={() => this.setState({ alertsModalOpen: false })}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Fade in={this.state.alertsModalOpen}>
            {this.renderAlertsModal()}
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default PostcodeSearch;
