import React from 'react';
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import PostcodeSearch from './components/PostcodeSearch';
import Admin from './components/Admin';
import Login from './components/Login';
import { getCookieValue } from './util/cookies';

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated
function AdminRoute({ ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location, history }) =>
        getCookieValue('authToken') ? (
          <Admin history={history} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App(props) {
  return (
    <BrowserRouter>
      <Route path="/" exact component={PostcodeSearch} />
      <AdminRoute path="/admin" />
      <Route path="/login" exact component={Login} />
    </BrowserRouter>
  );
}

export default App;
