import React, { Component } from 'react';
import { Typography, Paper, TextField, Button, Select, InputLabel, MenuItem, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UpdateIcon from '@material-ui/icons/Update';
import { updateOutcodeStatus, getAllOutcodes } from '../apis/postcodes';

const containerStyle = {
  margin: '0 auto',
  maxWidth: '600px',
};

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outcode: '',
      outcodes: [],
      status: '',
      notification: '',
      notificationType: '',
    };

    this.onLogout = this.onLogout.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const response = await getAllOutcodes();
    const outcodes = response.data.outcodes;
    outcodes.sort((a, b) => a.outcode.localeCompare(b.outcode));
    this.setState({ outcodes });
  }

  async onSubmit(e) {
    e.preventDefault();

    try {
      await updateOutcodeStatus(this.state.outcode, this.state.status);
      this.setState({ notification: 'Outcode state updated successfully', notificationType: 'success'});
    } catch (ex) {
      this.setState({ notification: 'Error updating outcode', notificationType: 'error'});
    }
  }

  onLogout() {
    document.cookie = "authToken=";
    this.props.history.push('/');
  }

  render() {
    return (
      <div style={containerStyle}>
        <Typography variant="h2" component="h1" style={{ marginBottom: '30px', textAlign: 'center' }}>Admin Console</Typography>
        <Paper elevation={3}>
          <div style={{ padding: '20px' }}>
            <form onSubmit={this.onSubmit}>
              <Typography>You can update the status of an outcode here:</Typography>
              <br />
              <Autocomplete
                id="input-outcode"
                name="input-outcode"
                options={this.state.outcodes}
                getOptionLabel={(option) => option.outcode}
                fullWidth
                style={{ marginBottom: '20px' }}
                onChange={(e, selected) => this.setState({ outcode: selected.outcode })}
                renderInput={(params) => <TextField {...params} label="Outcode" variant="outlined" />}
              />
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={this.state.status}
                onChange={(e) => this.setState({ status: e.target.value })}
                variant="outlined"
                fullWidth
                style={{ marginBottom: '20px' }}
              >
                <MenuItem value="green">Green</MenuItem>
                <MenuItem value="amber">Amber</MenuItem>
                <MenuItem value="red">Red</MenuItem>
              </Select>
              <br />
              <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
              }}>
                  <Button type="submit" color="primary" variant="contained" size="large" startIcon={<UpdateIcon />} disabled={!this.state.outcode || !this.state.status}>Update</Button>
                  <Button color="secondary" variant="contained" size="large" startIcon={<UpdateIcon />} onClick={this.onLogout}>Logout</Button>
              </div>  
            </form>
            
          </div>
        </Paper>
        <Snackbar
          open={this.state.notification}
          autoHideDuration={6000}
          onClose={() => this.setState({ notification: '', notificationType: '' })}
        >
          <MuiAlert elevation={6} variant="filled" severity={this.state.notificationType}>{this.state.notification}</MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

export default Admin;
