import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';

class Map extends Component {
  updatePolygons() {
    if (!this.map) {
      return;
    }

    this.map.data.setStyle((feature) => {
      if (this.props.amberOutcodes.includes(feature.getProperty('name'))) {
        return {
          fillColor: 'orange',
          fillOpacity: 0.6,
          strokeColor: 'darkorange',
          strokeOpacity: 0.5,
          strokeWeight: 0.3,
          label: {
            color: "blue",
            fontFamily: "Courier",
            fontSize: "24px",
            fontWeight: "bold",
            text: feature.getProperty('name'),
          }
        };
      } else if (this.props.redOutcodes.includes(feature.getProperty("name"))) {
        return {
          fillColor: 'red',
          fillOpacity: 0.6,
          strokeColor: 'darkred',
          strokeOpacity: 0.5,
          strokeWeight: 0.3,
          label: {
            color: "blue",
            fontFamily: "Courier",
            fontSize: "24px",
            fontWeight: "bold",
            text: feature.getProperty('name'),
          }
        };
      } else {
        return {
          fillColor: 'green',
          fillOpacity: 0.3,
          strokeColor: 'darkgreen',
          strokeOpacity: 0.5,
          strokeWeight: 0.3,
        };
      }
    });
  }
  resetMapStyle(data, outcode) {
    data.revertStyle();
    if (outcode) {
      data.forEach(feature => {
        if (outcode === feature.getProperty('name')) {
          data.overrideStyle(feature, {strokeWeight: 8, strokeOpacity: 1.0 })
        }
      });
    }
  }

  async handleApiLoaded(map, maps) {
    this.map = map;
    const scottishOutcodes = ['ZE', 'KW', 'HS', 'IV', 'AB', 'PH', 'DD', 'PA', 'FK', 'KY', 'G', 'EH', 'ML', 'KA', 'DG', 'TD'];
    scottishOutcodes.forEach(outcode => {
      //map.data.loadGeoJson(`https://raw.githubusercontent.com/miDrive/uk-outcode-geometry/master/json/${outcode}.json`);
      map.data.loadGeoJson(`${process.env.PUBLIC_URL}/outcode_json/${outcode}.json`);
    });
    map.data.setStyle({
      fillColor: 'green',
      fillOpacity: 0.4,
      strokeColor: 'darkgreen',
      strokeOpacity: 0.6,
      strokeWeight: 0.1,
    });

    // Highlight on mouse-over
    map.data.addListener('mouseover', (event) => {
      this.resetMapStyle(map.data, this.props.outcode);
      map.data.overrideStyle(event.feature, {strokeWeight: 8});
    });
    map.data.addListener('mouseout', (event) => {
      this.resetMapStyle(map.data, this.props.outcode);
    });
    map.data.addListener('click', (event) => {
      map.data.overrideStyle(event.feature, {strokeWeight: 8});
      const bounds = new maps.LatLngBounds(); 
      event.feature.getGeometry().forEachLatLng(function(latlng) {
        bounds.extend(latlng);
      });
      map.fitBounds(bounds);
      this.props.areaSelected(event.feature.getProperty('name'));
    });

    this.updatePolygons();
  }

  componentDidUpdate() {
    this.updatePolygons();
  }

  render() {
    return (
      <Paper elevation={3}>
        <div style={{ height: '650px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCU7frQU7kmL8Fbgzh6_tG5vvZ_Kv_h5Zg' }}
            center={this.props.centre}
            zoom={this.props.zoom}
            distanceToMouse={() => {}}
            //onClick={this.props.onClick}
            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            {this.props.showMarker
              ? (
                <RoomIcon
                  lat={this.props.centre.lat}
                  lng={this.props.centre.lng}
                  fontSize="large"
                  color="primary"
                />
              )
              : <span />}
          </GoogleMapReact>
        </div>
      </Paper>
    );
  }
}

Map.propTypes = {
  centre: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
  showMarker: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  redOutcodes: PropTypes.arrayOf(PropTypes.string),
  amberOutcodes: PropTypes.arrayOf(PropTypes.string),
  areaSelected: PropTypes.func.isRequired,
  outcode: PropTypes.string,
};

export default Map;
