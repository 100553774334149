import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

class PostcodeInformation extends Component {
  render() {
    if (!this.props.postcodeInfo) { return <div />; }

    let statusText = '';
    let statusColor = '';
    let statusIcon = null;
    switch(this.props.postcodeInfo.outcodestatus) {
      case 'GREEN':
        statusText = 'There is a low number of Covid-19 cases';
        statusColor = '#44FF44';
        statusIcon = <CheckCircleOutlineIcon style={{ fontSize: '146px' }} />;
        break;
      case 'AMBER':
        statusText = 'There is a slightly higher number of Covid-19 cases';
        statusColor = '#FF8C00';
        statusIcon = <ErrorOutlineIcon style={{ fontSize: '146px' }} />;
        break;
      case 'RED':
        statusText = 'There is a high number of Covid-19 cases';
        statusColor = '#FF4444';
        statusIcon = <ErrorOutlineIcon style={{ fontSize: '146px' }} />;
        break;
      default:
        statusText = 'There is no data on Covid-19 cases';
        statusColor = '#BBBBBB';
        statusIcon = <HelpOutlineIcon style={{ fontSize: '146px' }} />;
    }

    return (
      <Paper elevation={3} style={{ marginBottom: '10px' }}>
        <Box display="flex" flexDirection="row" style={{ backgroundColor: statusColor, padding: '20px' }}>
          {statusIcon}
          <div>
            <Typography variant="h6"><Box component="span" fontWeight="fontWeightBold">Status: </Box>{statusText}</Typography>
            <Typography variant="body1"><Box component="span" fontWeight="fontWeightBold">Postcode Area </Box>{this.props.postcodeInfo.outcode}</Typography>
            <Typography variant="body1"><Box component="span" fontWeight="fontWeightBold">District: </Box>{this.props.postcodeInfo.admin_district}</Typography>
            <Typography variant="body1"><Box component="span" fontWeight="fontWeightBold">Constituency: </Box>{this.props.postcodeInfo.constituency}</Typography>
          </div>
        </Box>
      </Paper>
    );
  }
}

PostcodeInformation.propTypes = {
  postcodeInfo: PropTypes.shape({
    postcode: PropTypes.string,
    status: PropTypes.string,
    outcode: PropTypes.string,
    outcodestatus: PropTypes.string,
    link1: PropTypes.string,
    link2: PropTypes.string,
    admin_district: PropTypes.string,
    constituency: PropTypes.string,
    postcodeArea: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
  isOutcode: PropTypes.bool,
};

export default PostcodeInformation;
