import axios from 'axios';
import { getCookieValue } from '../util/cookies';

/*const adminConfig = {
  headers: {
    'x-api-key': 'uNNXmp1IUU7kTBWGxi4Ea5gttv3vxjHP3rvjPJgC',
  },
}*/

const token = getCookieValue('authToken');
const adminConfig2 = {
  headers: { Authorization: `Bearer ${token}` }
}

function getPostcodeInfoFromLocation(lng, lat) {
  return axios.get('https://api.postcodes.io/postcodes/', {
    params: {
      lon: lng,
      lat,
    },
  });
}

function getPostcodeInfoFromPostcode(postcode) {
  const sanitisedPostcode = postcode.replace(/ /g, '');
  return axios.get(`https://vw2hbg9arc.execute-api.eu-west-2.amazonaws.com/poc/postcode/${sanitisedPostcode}`);
}

function getOutcodeInfoByOutcode(outcode) {
  return axios.get(`https://vw2hbg9arc.execute-api.eu-west-2.amazonaws.com/poc/outcode/${outcode}`);
}

function getOutcodesByStatus(status) {
  return axios.get(`https://vw2hbg9arc.execute-api.eu-west-2.amazonaws.com/poc/outcode/all/${status}`);
}

function getAllOutcodes() {
  return axios.get('https://vw2hbg9arc.execute-api.eu-west-2.amazonaws.com/poc/admin/outcode/all', adminConfig2);
}

function updateOutcodeStatus(outcode, status) {
  const body = {
    outcode,
    status,
  };

  return axios.put('https://vw2hbg9arc.execute-api.eu-west-2.amazonaws.com/poc/admin/outcode', body, adminConfig2);
}

export {
  getPostcodeInfoFromPostcode,
  getOutcodeInfoByOutcode,
  getPostcodeInfoFromLocation,
  getOutcodesByStatus,
  getAllOutcodes,
  updateOutcodeStatus,
};
