import axios from 'axios';

function getAuthToken(username, password) {
    const body = {
        username,
        password,
    };
    return axios.post('https://vw2hbg9arc.execute-api.eu-west-2.amazonaws.com/poc/oauth/token', body);
}

export {
    getAuthToken,
}